import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import {
  Box,
  Link,
  List,
  Avatar,
  Drawer,
  Hidden,
  Typography,
  ListSubheader,
  Button
} from '@material-ui/core';
// components
// import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import PoweredByAutone from './PoweredByAutone';

//
// import MenuLinks from './SidebarConfig';
import SidebarPage from './SidebarPage';
import SidebarDropDown from './SidebarDropDown';
import { getFilters } from '../../utils/API';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const DocStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(2.5),
  borderRadius: theme.shape.borderRadiusMd,
  backgroundColor:
    theme.palette.mode === 'light'
      ? alpha(theme.palette.primary.main, 0.08)
      : theme.palette.primary.lighter
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  margin: theme.spacing(1, 2.5, 5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}));

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
  user: PropTypes.object,
  jwtToken: PropTypes.string,
  currentFilters: PropTypes.object,
  setCurrentFilters: PropTypes.func
};

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
  user,
  jwtToken,
  currentFilters,
  setCurrentFilters
}) {
  const { pathname } = useLocation();
  const [filters, setFilters] = useState({});

  const { name } = user;

  const userImage = user['custom:imageURL'];
  const position = user['custom:position'];

  useEffect(() => {
    if (isOpenSidebar && onCloseSidebar) {
      onCloseSidebar();
    }

    getFilters(jwtToken)
      .then((res) => {
        setFilters(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [pathname]);

  const renderContent = (
    <Scrollbar>
      <Box sx={{ px: 2.5, py: 3, pl: 2 }}>
        <Box
          component="img"
          alt="logo"
          src="/static/brand/Logo.png"
          height={30}
          sx={{ ml: 1, mt: 1 }}
        />
      </Box>

      <Link underline="none" component={RouterLink} to="#">
        <AccountStyle>
          <Avatar alt={name} src={userImage} />
          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
              {name}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {position}
            </Typography>
          </Box>
        </AccountStyle>
      </Link>

      <List
        disablePadding
        key="Apps"
        subheader={
          <>
            <ListSubheader
              disableSticky
              disableGutters
              sx={{
                mt: 3,
                mb: 2,
                pl: 5,
                color: 'text.primary',
                typography: 'overline'
              }}
            >
              Apps
            </ListSubheader>
            <SidebarPage title="Allocation" href="" />
          </>
        }
      />

      <List
        disablePadding
        key="Filters"
        subheader={
          <>
            <ListSubheader
              disableSticky
              disableGutters
              sx={{
                mt: 3,
                mb: 2,
                pl: 5,
                color: 'text.primary',
                typography: 'overline'
              }}
            >
              Filters
            </ListSubheader>

            {Object.keys(filters).map((filter) => (
              <SidebarDropDown
                title={filter}
                key={filter}
                values={filters[filter]}
                setCurrentFilters={setCurrentFilters}
                currentFilters={currentFilters}
              />
            ))}
          </>
        }
      />

      <Box sx={{ px: 2.5, pb: 3, mt: 5, ml: 2, mr: 2 }}>
        <DocStyle>
          <Typography
            gutterBottom
            variant="subtitle1"
            sx={{ color: 'grey.800' }}
          >
            Hi {name}!
          </Typography>
          <Typography variant="body2" sx={{ mb: 2, color: 'grey.600' }}>
            Need help?
            <br /> Please contact us
          </Typography>
          <Button fullWidth variant="contained" href="mailto:support@autone.io">
            Support
          </Button>
        </DocStyle>
      </Box>

      <Box
        sx={{
          mb: 10,
          mt: 2
        }}
        style={{ textAlign: 'center' }}
      >
        <PoweredByAutone />
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      <Hidden lgUp>
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: 'background.default' }
          }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
    </RootStyle>
  );
}
