import axios from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';
// import warehouseData from './mock_data/allocation_diptyqueparis_warehouse.json';
// import filterData from './mock_data/allocation_diptyqueparis_filter.json';
// import locationData from './mock_data/allocation_diptyqueparis_locations.json';
// import allocationData from './mock_data/allocation_diptyqueparis_allocation.json';
import warehouseData from './mock_data/allocation_emilia_wickstead_warehouse.json';
import filterData from './mock_data/allocation_emilia_wickstead_filter.json';
import locationData from './mock_data/allocation_emilia_wickstead_locations.json';
import allocationData from './mock_data/allocation_emilia_wickstead_allocation.json';

const mock = new AxiosMockAdapter(axios, { delayResponse: 1200 });

mock.onGet('/warehouse').reply(200, warehouseData);

export function getWarehouse() {
  return axios.get('/warehouse');
}

mock.onGet('/filters').reply(200, filterData);

export function getFilters() {
  return axios.get('/filters');
}

mock.onGet('/getfocus').reply(200, { sku_code: [], product_code: [] });

export function getFocus(jwtToken) {
  return axios.get('/getfocus', {
    headers: {
      Authorization: jwtToken
    }
  });
}
// prettier-ignore
mock.onGet('/notifications').reply(200, { week: 'Wk. 2 October 2021', dataAsOf: '5th October 2021' });

export function getNotifications() {
  return axios.get('/notifications');
}

mock.onPost('/edit').reply(200);

export function edits() {
  return axios.post('/edit');
}

mock.onPost('/removecombination').reply(200);

export function removeCombination() {
  return axios.post('/removecombination');
}

mock.onPost('/unremovecombination').reply(200);

export function unRemoveCombination() {
  return axios.post('/unremovecombination');
}

mock.onPost('/unremovelocationcombination').reply(200);

export function unRemoveLocationCombination() {
  return axios.post('/unremovelocationcombination');
}

mock.onPost('/focussku').reply(200);

export function postFocusSKU() {
  return axios.post('/focussku');
}

mock.onPost('/focusproduct').reply(200);

export function postFocusProduct() {
  return axios.post('/focusproduct');
}

mock.onGet('/locations').reply(200, locationData);

export function getLocations(jwtToken) {
  return axios.get('/locations', {
    headers: {
      Authorization: jwtToken
    }
  });
}

mock.onGet('/submission').reply(200, []);

export function getSubmission() {
  return axios.get('/submission', {});
}

mock.onPost('/email/allocationemail').reply(200, []);

export function allocationEmail() {
  return axios.post('/email/allocationemail');
}

mock.onPost('/additionallocation').reply(200, []);

export function postAdditionalLocation() {
  return axios.post('/additionallocation');
}

mock.onGet('/allocation').reply(200, allocationData);

export function getAllocation() {
  return axios.get('/allocation');
}

export default {
  getAllocation,
  getWarehouse,
  getFilters,
  removeCombination,
  unRemoveCombination,
  unRemoveLocationCombination,
  edits,
  getNotifications,
  allocationEmail,
  getSubmission,
  postFocusProduct,
  postFocusSKU,
  getFocus,
  getLocations,
  postAdditionalLocation
};
