// scroll bar
import 'simplebar/src/simplebar.css';

import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import MixPanelProvider from 'react-mixpanel-provider-component';
import Cohere from 'cohere-js';

import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import App from './App';
import config from './aws-exports';

Amplify.configure(config);

// ----------------------------------------------------------------------

ReactDOM.render(
  <MixPanelProvider token="9671d79a2d3f96453a89037ab936bc64">
    <App />
  </MixPanelProvider>,
  document.getElementById('root')
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
