import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';

import { HelmetProvider } from 'react-helmet-async';

import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
// routes
// redux
// test push3!
import { store, persistor } from './redux/store';
// theme
import ThemeConfig from './theme';
// components Y!
import LoadingScreen from './components/LoadingScreen';
import Login from './views/authentication/Login';
import Routes from './routes/Routes';

import NotistackProvider from './components/NotistackProvider';

// ------------------------------------------------------------------------

function App() {
  const [user, setUser] = useState();
  const [jwtToken, setjwtToken] = useState();

  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((authuser) => {
        setUser(authuser.attributes);
        setjwtToken(authuser.signInUserSession.idToken.getJwtToken());
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <HelmetProvider>
      <ReduxProvider store={store}>
        <PersistGate loading={<LoadingScreen />} persistor={persistor}>
          <ThemeConfig>
            {!user || !jwtToken ? (
              <Login setUser={setUser} setjwtToken={setjwtToken} />
            ) : (
              <NotistackProvider>
                <Routes
                  user={user}
                  jwtToken={jwtToken}
                  setUser={setUser}
                  setjwtToken={setjwtToken}
                />
              </NotistackProvider>
            )}
          </ThemeConfig>
        </PersistGate>
      </ReduxProvider>
    </HelmetProvider>
  );
}

export default App;
